<template>
  <div class="container">
    <div class="filters">
      <MaterialButton text="Ultimi 2 giorni" :class="{ deselected: filterActive !== 'lastTwoDays' }" @click="selectLastTwoDays()" />
      <MaterialButton text="Ultimi 7 giorni" :class="{ deselected: filterActive !== 'lastSevenDays' }" @click="selectLastSevenDays()" />
      <MaterialButton text="Ultimi 2 settimane" :class="{ deselected: filterActive !== 'lastTwoWeeks' }" @click="selectLastTwoWeeks()" />
    </div>

    <div class="download">
      <div class="date-box">
        <label for="date-from">Data inizio</label>
        <input id="date-from" :value="getDateDisplayed(dateStart)" type="date" required @input="dateFromChanged($event)">
      </div>

      <div class="date-box">
        <label for="date-to">Data fine</label>
        <input id="date-to" :value="getDateDisplayed(dateEnd)" type="date" required @input="dateToChanged($event)">
      </div>
      <MaterialButton text="Scarica" type="warning" @click="download()" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import { navbarStore } from '@/stores/navbar'
import dayjs from 'dayjs'
import { reportbackoffice } from '@/api'

const dateStart = ref('')
const dateEnd = ref('')

const filterActive = ref('')

const navbar = navbarStore()
const toast = inject('$toast')

const getDateDisplayed = (date) => {
  if (date) {
    return new Date(date).toISOString().slice(0, 10)
  }
  return ''
}

const dateFromChanged = ({ target }) => {
  if (!target.value) return
  dateStart.value = new Date(target.value)
  filterActive.value = 'custom'
}

const dateToChanged = ({ target }) => {
  if (!target.value) return
  dateEnd.value = new Date(target.value)
  filterActive.value = 'custom'
}

onMounted(() => {
  navbar.title = 'Annunci aggregati'
  selectLastSevenDays()
})

/**
 * set dateStart two days ago and dateEnd today
 */
const selectLastTwoDays = () => {
  dateStart.value = new Date()
  dateStart.value.setDate(dateStart.value.getDate() - 2)

  dateEnd.value = new Date()

  filterActive.value = 'lastTwoDays'
}

/**
 * set dateStart seven days ago and dateEnd today
 */
const selectLastSevenDays = () => {
  dateStart.value = new Date()
  dateStart.value.setDate(dateStart.value.getDate() - 7)

  dateEnd.value = new Date()

  filterActive.value = 'lastSevenDays'
}

/**
 * set dateStart 14 days ago and dateEnd today
 */
const selectLastTwoWeeks = () => {
  dateStart.value = new Date()
  dateStart.value.setDate(dateStart.value.getDate() - 14)

  dateEnd.value = new Date()

  filterActive.value = 'lastTwoWeeks'
}

/**
 * download report
 */
const download = () => {
  reportbackoffice
    .get('/sales/csv', {
      params: {
        fromDate: dayjs(dateStart.value).format('YYYY-MM-DD'),
        toDate: dayjs(dateEnd.value).format('YYYY-MM-DD'),
        reportType: 'aggregate_italian_jobs'
      },
      responseType: 'blob'
    })
    .then((response) => {
      const href = URL.createObjectURL(response.data)
      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'file.csv') //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      toast.success('Il file verrà scaricato automaticamente')
    })
    .catch((err) => {
      toast.warning('Errore durante il download: ' + err)
    })
}
</script>

<style lang="scss" scoped>
.container {
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 2em;

  .filters {
    display: flex;
    gap: 1rem;

    .deselected {
      opacity: 0.5;
    }
  }

  .download {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    align-content: center;

    .date-box {
			color: #fffa;
			border: thin solid #f4f4f488;
			border-radius: 5px;
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			flex-wrap: nowrap;
			gap: 0.5rem;
			flex-grow: 1;

			label {
				white-space: nowrap;
			}

			input {
				color: #ffffff;
				outline: none;
				border: none;
				background: transparent;
				width: 100%;
				position: relative;

				&::-webkit-calendar-picker-indicator {
					background: transparent;
					bottom: 0;
					color: transparent;
					cursor: pointer;
					height: auto;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					width: auto;
				}
			}
		}
  }
}
</style>
